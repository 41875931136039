import axios, { AxiosRequestConfig } from 'axios';
// config
// import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BRIGHTHUB_SERVER_API });

axiosInstance.interceptors.request.use((request) => {
  if (!request.headers['Skip-Auth']) {
    let token = localStorage.getItem('token');
    if (token && token !== undefined && token !== 'undefined') {
      request.headers.Authorization = token;
    }
  } else {
    delete request.headers['Skip-Auth'];
  }
  return request;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// Define a custom fetcher function that makes a POST request and is generic
export const postFetcher = async <T>(args: [string, any, AxiosRequestConfig?]): Promise<T> => {
  const [url, data, config = {}] = args; // Destructure the arguments with a default empty config
  const res = await axiosInstance.post<T>(url, data, { ...config }); // Use Axios' post method
  return res.data; // Return the response data directly
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  account: {
    onboarding: {
      uploadQrPanel: '/management/onboarding/save_qr_panel',
    }
  },
  aisearch: {
    updateResponse: '/brighthealth/minion/aisearch/update_response',
    storeResponse: '/brighthealth/minion/aisearch/store_response',
  },
};
