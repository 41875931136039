import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import {
  AppState,
  useAuth0,
  Auth0Provider,
  LogoutOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
  withAuthenticationRequired
} from '@auth0/auth0-react';
// config
import { AUTH0_API, PATH_AFTER_LOGIN } from 'src/config-global';
// hooks
import { useRouter } from 'src/routes/hooks';
// routes
import { paths } from 'src/routes/paths';
import {
  ROLE_BASED_PATHS,
} from 'src/data/accesscontrol';
// context
import { AuthContext } from './auth-context';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from 'jsonwebtoken';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

type ConnectionType = 'sms' | 'email';

interface MyTokenPayload extends JwtPayload {
  'https://brighthub.in/roles'?: string[];
  firstTimeLogin?: boolean;
}

function AuthProviderWrapper({ children }: Props) {
  const {
    isAuthenticated,
    user,
    isLoading,
    getIdTokenClaims,
    loginWithRedirect,
    loginWithPopup,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const router = useRouter();

  const [popupClick, setPopupClick] = useState(true);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  // We'll consider Auth initialization complete when isLoading is false
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [zohoHCAsapToken, setZohoHCAsapToken] = useState('');
  const [tokenExpiryTime, setTokenExpiryTime] = useState<number | null>(null);

  useEffect(() => {
    if (!isLoading) {
      setIsAuthInitialized(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.removeItem('activationStage');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const handlePostLoginRedirection = async () => {
      if (isAuthenticated) {
        const isLoggingIn = sessionStorage.getItem('isLoggingIn');
        sessionStorage.removeItem('isLoggingIn'); // Clear the flag

        const tokenClaims = await getIdTokenClaims();
        if (!tokenClaims) return;
        const decodedToken = jwtDecode<MyTokenPayload>(tokenClaims.__raw);
        const roles = decodedToken['https://brighthub.in/roles'] || [];
        const passwordSet = decodedToken['https://brighthub.in/passwordSet'] !== undefined ? decodedToken['https://brighthub.in/passwordSet'] : true;
        const unknownUser = decodedToken['https://brighthub.in/unknownUser'] !== undefined ? decodedToken['https://brighthub.in/unknownUser'] : true;
        const activationPending = decodedToken['https://brighthub.in/activationPending'] !== undefined ? decodedToken['https://brighthub.in/activationPending'] : false;
        const activationStarted = decodedToken['https://brighthub.in/activationStarted'] !== undefined ? decodedToken['https://brighthub.in/activationStarted'] : false;
        setUserRoles(roles);

        const returnToPath = sessionStorage.getItem('returnToPath');
        sessionStorage.removeItem('returnToPath');

        if (!passwordSet && !localStorage.getItem('hasChangedPassword')) {
          router.push(paths.auth.setPassword);
        } else if (unknownUser) {
          router.push(paths.dashboard.account.activationLanding);
        } else if (activationPending) {
          const activationStage = sessionStorage.getItem('activationStage');
          if (!activationStage) {
            if (activationStarted) {
              router.push(paths.dashboard.account.resumeActivation);
            } else {
              router.push(paths.dashboard.account.activationSplash);
            }
          }
          switch (activationStage) {
            case 'splash':
              router.push(paths.dashboard.account.activationSplash);
              break;
            case 'init':
              router.push(paths.dashboard.account.activation);
              break;
            case 'activated':
              router.push(paths.dashboard.account.activationComplete);
              break;
            case 'error':
              router.push(paths.dashboard.account.activationError);
              break;
            case 'pending-approval':
              router.push(paths.dashboard.account.pendingApproval);
              break;
          }
        } else if (returnToPath && returnToPath !== '/' && returnToPath !== '/dashboard') {
          router.push(returnToPath);
        } else if (isLoggingIn) {
          const primaryRole = roles[0];
          const roleBasedPath = ROLE_BASED_PATHS[primaryRole as keyof typeof ROLE_BASED_PATHS] || PATH_AFTER_LOGIN;
          router.push(roleBasedPath);
        }
      }
    };

    handlePostLoginRedirection();
  }, [isAuthenticated, getIdTokenClaims, router]);

  // Commenting Zoho till rest of developments are complete
  // useEffect(() => {
  //   const getZohoHCAsapToken = async () => {
  //     if (isAuthenticated) {
  //       const tokenClaims = await getIdTokenClaims();
  //       if (!tokenClaims) return;
  //       const decodedToken = jwtDecode<MyTokenPayload>(tokenClaims.__raw);
  //       const zohoJwtToken = decodedToken['zoho_jwt'] || '';
  //       setZohoHCAsapToken(zohoJwtToken);
  //     }
  //   };

  //   getZohoHCAsapToken();
  // }, [isAuthenticated, getIdTokenClaims, router]);


  // SWITCHING ACCOUNT - KEEPING PLACEHOLDER, NOT TESTED
  const switchAccount = useCallback(async (accountId: string) => {
    try {
      // // Optionally, use accountId to determine the appropriate audience or scope
      // const audience = `https://api.brighthub.in/${accountId}`;
      // const scope = `openid profile email account:${accountId}`;

      // const accessToken = await getAccessTokenSilently({ audience, scope });

      // // Decode the new token to extract roles and other claims
      // const decodedToken = jwtDecode<MyTokenPayload>(accessToken);
      // const roles = decodedToken['https://brighthub.in/roles'] || [];
      const roles = ['user']

      // Update context with new token, roles, and other relevant info
      setUserRoles(roles);
      // You may also want to store the new accessToken in the state or context if needed
    } catch (error) {
      console.error('Error switching accounts', error);
    }
  }, [getAccessTokenSilently]);


  // LOGIN WITH EMAIL
  const loginWithEmailOtp = useCallback(async (options?: RedirectLoginOptions) => {
    await loginWithRedirect?.({
      authorizationParams: {
        connection: 'email',
        response_type: 'code',
        redirect_uri: window.location.origin + '/auth/callback', // Ensure to set the correct redirect URI
      },
      appState: options?.appState, // Pass the appState here
      ...options, // Spread any additional options passed
    });
  }, [loginWithRedirect]);

  // LOGIN WITH MOBILE
  const loginWithMobileOtp = useCallback(async (options?: RedirectLoginOptions) => {
    await loginWithRedirect?.({
      authorizationParams: {
        connection: 'sms',
        response_type: 'code',
        redirect_uri: window.location.origin + '/auth/callback', // Ensure to set the correct redirect URI
      },
      appState: options?.appState, // Pass the appState here
      ...options, // Spread any additional options passed
    });
  }, [loginWithRedirect]);

  // LOGIN
  const handleLoginWithPopup = useCallback(async (options?: PopupLoginOptions) => {
    await loginWithPopup?.(options);
    setPopupClick(false);
  }, [loginWithPopup]);

  // LOGOUT
  const handleLogout = useCallback(async (options?: LogoutOptions) => {
    logout?.(options);
    window.location.href = window.location.origin;
  }, [logout]);

  useEffect(() => {
    if (tokenExpiryTime) {
      const timeUntilExpiry = tokenExpiryTime - Date.now() - (1 * 60 * 1000); // Logout 1 minutes before expiry
      // console.log('timeUntilExpiry', timeUntilExpiry)
      const timerId = setTimeout(() => {
        handleLogout();
        // console.log('Logged out due to token expiry');
      }, timeUntilExpiry);

      return () => clearTimeout(timerId);
    }
  }, [tokenExpiryTime, handleLogout]);

  // console.log('tokenExpiryTime', tokenExpiryTime, Date.now())

  const getAccessToken = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      if (!accessToken) {
        throw new Error('Access token is undefined');
      }
      const decodedToken = jwtDecode<MyTokenPayload>(accessToken);
      console.log('decodedToken', decodedToken)
      if (decodedToken.exp !== undefined) {
        const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
        setTokenExpiryTime(expiryTime);
      }
      return accessToken;
    } catch (error) {
      console.error('Error getting access token', error);
      throw error;
    }
  }, [getAccessTokenSilently]);


  // FEDERATED SIGN IN
  const federatedSignIn = useCallback(
    async (provider: string) => {
      await loginWithRedirect?.({
        // Use authorizationParams to pass in additional Auth parameters like connection
        authorizationParams: {
          connection: provider, // Specify the connection (identity provider) here
        },
      });
    },
    [loginWithRedirect]
  );


  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';

  const status = popupClick && isLoading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(() => ({
    user: {
      ...user,
      displayName: user?.name,
      photoURL: user?.picture,
      role: 'admin', // Note: Adjust role management as per your application's requirements
    },
    method: 'auth0',
    loading: status === 'loading',
    authenticated: status === 'authenticated',
    unauthenticated: status === 'unauthenticated',
    isAuthInitialized,
    loginWithRedirect,
    loginWithEmailOtp,
    loginWithMobileOtp,
    loginWithPopup: handleLoginWithPopup,
    logout: handleLogout,
    federatedSignIn,
    getAccessToken,
    zohoHCAsapToken,
    userRoles,
  }), [
    handleLoginWithPopup,
    handleLogout,
    loginWithRedirect,
    loginWithEmailOtp,
    loginWithMobileOtp,
    status,
    user,
    isAuthInitialized,
    federatedSignIn,
    getAccessToken,
    zohoHCAsapToken,
    userRoles,
  ]);

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

// ----------------------------------------------------------------------

export const AuthProvider = ({ children }: Props) => {
  const domain = AUTH0_API.domain ?? '';
  const clientId = AUTH0_API.clientId ?? '';

  // Dynamically set the redirect URI based on the current window location
  const redirectUri = `${window.location.origin}/auth/callback`;

  const onRedirectCallback = useCallback((appState?: AppState) => {
    const returnToPath = appState?.returnTo || window.location.pathname;
    sessionStorage.setItem('returnToPath', returnToPath);

    window.location.replace(returnToPath);
  }, []);

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: 'https://api.brighthub.in/',
        scope: 'openid profile email'
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};