// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';
import ActivationErro from 'src/pages/dashboard/account/activation-error';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  HOME: '/',
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  root: '/',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-Minimal-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id: string) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    verify: `${ROOTS.AUTH}/verify`,
    register: `${ROOTS.AUTH}/register`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    setPassword: `${ROOTS.AUTH}/set-password`,
    oAuthCallback: `${ROOTS.AUTH}/oauth-callback`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      setPassword: `${ROOTS.AUTH}/amplify/set-password`,
      oAuthCallback: `${ROOTS.AUTH}/amplify/oauth-callback`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
      setPassword: `${ROOTS.AUTH}/auth0/set-password`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      setPassword: `${ROOTS.AUTH_DEMO}/classic/set-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      setPassword: `${ROOTS.AUTH_DEMO}/modern/set-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    account: {
      activationLanding: `${ROOTS.DASHBOARD}/account/activation-landing`,
      activationSplash: `${ROOTS.DASHBOARD}/account/activation-splash`,
      resumeActivation: `${ROOTS.DASHBOARD}/account/resume-activation`,
      activationComplete: `${ROOTS.DASHBOARD}/account/activation-complete`,
      pendingApproval: `${ROOTS.DASHBOARD}/account/pending-approval`,
      activationError: `${ROOTS.DASHBOARD}/account/activation-error`,
      activation: `${ROOTS.DASHBOARD}/account/activation`,
    },
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    team: {
      manage: `${ROOTS.DASHBOARD}/team/manage`,
      invite: `${ROOTS.DASHBOARD}/team/invite-member`,
      edit: (userId: string) => `${ROOTS.DASHBOARD}/team/member/${userId}/edit`
    },
    brighthealth: {
      overview: `${ROOTS.DASHBOARD}/brighthealth/overview`,
      myprofile: `${ROOTS.DASHBOARD}/brighthealth/myprofile`,
      myclinicprofile: `${ROOTS.DASHBOARD}/brighthealth/profile`,
      practices: `${ROOTS.DASHBOARD}/brighthealth/practices`,
      practitioners: `${ROOTS.DASHBOARD}/brighthealth/practitioners`,
      newPractice: `${ROOTS.DASHBOARD}/brighthealth/practice/new`,
      newPractitioner: `${ROOTS.DASHBOARD}/brighthealth/practitioner/new`,
      practiceEdit: (providerId: string) => `${ROOTS.DASHBOARD}/brighthealth/practice/${providerId}/edit`,
      practitionerEdit: (providerId: string) => `${ROOTS.DASHBOARD}/brighthealth/practitioner/${providerId}/edit`,
      services: {
        master: `${ROOTS.DASHBOARD}/brighthealth/services/master`,
        serviceEdit: (serviceId: string) => `${ROOTS.DASHBOARD}/brighthealth/services/${serviceId}/edit`,
        manage: `${ROOTS.DASHBOARD}/brighthealth/services`
      },
      packages: `${ROOTS.DASHBOARD}/brighthealth/packages`
    },
    brightpay: {
      overview: `${ROOTS.DASHBOARD}/brightpay/overview`,
      paymentRequests: `${ROOTS.DASHBOARD}/brightpay/payment-requests`,
      paymentLinks: `${ROOTS.DASHBOARD}/brightpay/payment-links`,
      newPaymentLink: `${ROOTS.DASHBOARD}/brightpay/payment-link/new`,
      paymentLink: (paymentLinkId: string) => `${ROOTS.DASHBOARD}/brightpay/payment-link/${paymentLinkId}`,
      paymentPages: `${ROOTS.DASHBOARD}/brightpay/payment-pages`,
      paymentPagesByType: (type: string) => `${ROOTS.DASHBOARD}/brightpay/payment-pages/${type}`,
      accountPaymentPages: (accountId: string) => `${ROOTS.DASHBOARD}/brightpay/payment-pages/${accountId}`,
      accountPaymentPagesByType: (accountId: string, type: string) => `${ROOTS.DASHBOARD}/brightpay/payment-pages/${accountId}/${type}`,
      paymentPage: (paymentPageId: string) => `${ROOTS.DASHBOARD}/brightpay/payment-page/${paymentPageId}`,
      accountPaymentPage: (accountId: string, paymentPageId: string) => `${ROOTS.DASHBOARD}/brightpay/payment-page/${accountId}/${paymentPageId}`,
      myQrCode: `${ROOTS.DASHBOARD}/brightpay/qr`,
      reports: `${ROOTS.DASHBOARD}/brightpay/practitioners`,
      transactions: `${ROOTS.DASHBOARD}/brightpay/transactions`,
      transaction: (transactionId: string) => `${ROOTS.DASHBOARD}/brightpay/transaction/${transactionId}`,
      settlements: `${ROOTS.DASHBOARD}/brightpay/settlements`,
      settlement: (settlementId: string) => `${ROOTS.DASHBOARD}/brightpay/settlement/${settlementId}`,
      credits: `${ROOTS.DASHBOARD}/brightpay/credits`,
    },
    brightai: {
      landing: `${ROOTS.DASHBOARD}/brightai`,
      stocksmart: `${ROOTS.DASHBOARD}/brightai/stocksmart`,
      medisight: `${ROOTS.DASHBOARD}/brightai/medisight`,
      smartpractice: `${ROOTS.DASHBOARD}/brightai/smartpractice`,
      patientflow: `${ROOTS.DASHBOARD}/brightai/patientflow`,
    },
    accounts: {
      root: `${ROOTS.DASHBOARD}/accounts`,
      list: `${ROOTS.DASHBOARD}/accounts/list`,
      search: `${ROOTS.DASHBOARD}/accounts/search`,
      activity: `${ROOTS.DASHBOARD}/accounts/activity`,
      logistics: `${ROOTS.DASHBOARD}/accounts/logistics`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/accounts/${id}/edit`,
      prepareQr: (accountId: string) => `${ROOTS.DASHBOARD}/accounts/${accountId}/prepare-qr-panel`,
      prepareQrSticker: (accountId: string) => `${ROOTS.DASHBOARD}/accounts/${accountId}/prepare-qr-sticker`,
    },
    leads: {
      root: `${ROOTS.DASHBOARD}/leads`,
      list: `${ROOTS.DASHBOARD}/leads/list`,
      search: `${ROOTS.DASHBOARD}/leads/search`,
      activity: `${ROOTS.DASHBOARD}/leads/activity`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/leads/${id}/edit`,
    },
    support: {
      root: `${ROOTS.DASHBOARD}/support`,
      submitTicket: `${ROOTS.DASHBOARD}/support/submit-ticket`,
      tickets: `${ROOTS.DASHBOARD}/support/tickets`,
      ticket: (ticketId: string) => `${ROOTS.DASHBOARD}/support/ticket/${ticketId}`,
      newTicket: `${ROOTS.DASHBOARD}/support/ticket/new`,
      faqs: `${ROOTS.DASHBOARD}/support/faqs`,
      faq: (faqId: string) => `${ROOTS.DASHBOARD}/support/faq/${faqId}`,
      newFaq: `${ROOTS.DASHBOARD}/support/faq/new`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
