import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
export const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// ACCOUNTS
const AccountsListPage = lazy(() => import('src/pages/dashboard/accounts/list'));
const AccountsSearchPage = lazy(() => import('src/pages/dashboard/accounts/search'));
const AccountsActivityPage = lazy(() => import('src/pages/dashboard/accounts/activity'));
const AccountsLogisticsPage = lazy(() => import('src/pages/dashboard/accounts/logistics'));
const AccountEditPage = lazy(() => import('src/pages/dashboard/accounts/edit'));
const AccountPrepareQrPanelPage = lazy(() => import('src/pages/dashboard/accounts/prepare-qr-panel'));
const AccountPrepareQrStickerPage = lazy(() => import('src/pages/dashboard/accounts/prepare-qr-sticker'));
// LEADS
const LeadsListPage = lazy(() => import('src/pages/dashboard/leads/list'));
const LeadsActivityPage = lazy(() => import('src/pages/dashboard/leads/activity'));
const LeadsEditPage = lazy(() => import('src/pages/dashboard/leads/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// BRIGHTHEALTH
const BrightHealthPracticesPage = lazy(() => import('src/pages/dashboard/brighthealth/practices'));
const BrightHealthPractitionersPage = lazy(() => import('src/pages/dashboard/brighthealth/practitioners'));
const BrightHealthEditPracticePage = lazy(() => import('src/pages/dashboard/brighthealth/practice/edit'));
const BrightHealthEditPractitionerPage = lazy(() => import('src/pages/dashboard/brighthealth/practitioner/edit'));

// BRIGHTPAY
const BrightPayTransactionsPage = lazy(() => import('src/pages/dashboard/brightpay/transactions/list'));
const BrightPayTransactionDetailsPage = lazy(() => import('src/pages/dashboard/brightpay/transactions/details'));

const BrightPaySettlementsPage = lazy(() => import('src/pages/dashboard/brightpay/settlements/list'));
const BrightPaySettlementDetailsPage = lazy(() => import('src/pages/dashboard/brightpay/settlements/details'));

const BrightPayPaymentLinksPage = lazy(() => import('src/pages/dashboard/brightpay/payment-links/list'));
const BrightPayOverviewPage = lazy(() => import('src/pages/dashboard/brightpay/overview'));
const BrightPayNewPaymentLinkPage = lazy(() => import('src/pages/dashboard/brightpay/payment-links/new'));
const BrightPayPaymentLinkViewEditPage = lazy(() => import('src/pages/dashboard/brightpay/payment-links/view'));

const BrightPayPaymentPages = lazy(() => import('src/pages/dashboard/brightpay/payment-pages/list'));
const BrightPayPaymentPage = lazy(() => import('src/pages/dashboard/brightpay/payment-pages/view'));

const BrightPayQrPanels = lazy(() => import('src/pages/dashboard/brightpay/qr-panels/view'));

// BRIGHTAI
const BrightAiResearchAssistantPage = lazy(() => import('src/pages/dashboard/brightai/research-assistant'));

// SERVICES
const ServicesManagementPage = lazy(() => import('src/pages/dashboard/brighthealth/services/manage'));
const ServicesMasterPage = lazy(() => import('src/pages/dashboard/brighthealth/services/master'));
const ServicesEditPage = lazy(() => import('src/pages/dashboard/brighthealth/services/edit'));

// ACCOUNT
const AccountActivation = lazy(() => import('src/pages/dashboard/account/activation'));
const AccountActivationLanding = lazy(() => import('src/pages/dashboard/account/activation-landing'));
const AccountActivationSplash = lazy(() => import('src/pages/dashboard/account/activation-splash'));
const ResumeAccountActivation = lazy(() => import('src/pages/dashboard/account/resume-activation'));
const AccountActivationComplete = lazy(() => import('src/pages/dashboard/account/activation-complete'));
const AccountPendingApproval = lazy(() => import('src/pages/dashboard/account/pending-approval'));
const AccountActivationError = lazy(() => import('src/pages/dashboard/account/activation-error'));

// SUPPORT
const HelpCenterPage = lazy(() => import('src/pages/dashboard/support/help-center'));
const SubmitTicketPage = lazy(() => import('src/pages/dashboard/support/submit-ticket'));

// TEAM
const ManageTeamPage = lazy(() => import('src/pages/dashboard/team/manage'));
const InviteTeamMemberPage = lazy(() => import('src/pages/dashboard/team/invite'));
const EditTeamMemberPage = lazy(() => import('src/pages/dashboard/team/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'account',
        children: [
          { path: 'activation', element: <AccountActivation /> },
          { path: 'activation-splash', element: <AccountActivationSplash />},
          { path: 'resume-activation', element: <ResumeAccountActivation /> },
          { path: 'activation-landing', element: <AccountActivationLanding /> },
          { path: 'activation-complete', element: <AccountActivationComplete /> },
          { path: 'activation-error', element: <AccountActivationError /> },
          { path: 'pending-approval', element: <AccountPendingApproval /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'team',
        children: [
          { element: <ManageTeamPage />, index: true },
          { path: 'manage', element: <ManageTeamPage /> },
          { path: 'invite-member', element: <InviteTeamMemberPage /> },
          { path: ':userId/edit', element: <EditTeamMemberPage /> },
        ],
      },
      {
        path: 'accounts',
        children: [
          { element: <AccountsListPage />, index: true },
          { path: 'list', element: <AccountsListPage /> },
          { path: 'search', element: <AccountsSearchPage /> },
          { path: 'activity', element: <AccountsActivityPage /> },
          { path: ':id/edit', element: <AccountEditPage /> },
          { path: ':accountId/prepare-qr-panel', element: <AccountPrepareQrPanelPage /> },
          { path: ':accountId/prepare-qr-sticker', element: <AccountPrepareQrStickerPage /> },
          { path: 'logistics', element: <AccountsLogisticsPage /> },
        ],
      },
      {
        path: 'leads',
        children: [
          { element: <LeadsListPage />, index: true },
          { path: 'list', element: <LeadsListPage /> },
          { path: 'activity', element: <LeadsActivityPage /> },
          { path: ':id/edit', element: <LeadsEditPage /> },
        ],
      },
      {
        path: 'brighthealth',
        children: [
          { element: <BrightHealthEditPracticePage />, index: true },
          { path: 'profile', element: <BrightHealthEditPracticePage /> },
          { path: 'myprofile', element: <BrightHealthEditPractitionerPage /> },
          { path: 'practices', element: <BrightHealthPracticesPage /> },
          { path: 'practitioners', element: <BrightHealthPractitionersPage /> },
          { path: 'practice/:providerId/edit', element: <BrightHealthEditPracticePage /> },
          { path: 'practitioner/:providerId/edit', element: <BrightHealthEditPractitionerPage /> },
          { path: 'services/master', element: <ServicesMasterPage /> },
          { path: 'services', element: <ServicesManagementPage /> },
          { path: 'services/:serviceId/edit', element: <ServicesEditPage /> },
          { path: 'packages', element: <ServicesManagementPage /> },
          { path: 'packages/:packageId/edit', element: <ServicesManagementPage /> },
        ],
      },
      {
        path: 'brightpay',
        children: [
          { element: <BrightPayOverviewPage />, index: true },
          { path: 'overview', element: <BrightPayOverviewPage /> },
          { path: 'transactions', element: <BrightPayTransactionsPage /> },
          { path: 'transaction/:transactionId', element: <BrightPayTransactionDetailsPage /> },
          { path: 'payment-links', element: <BrightPayPaymentLinksPage /> },
          { path: 'payment-link/new', element: <BrightPayNewPaymentLinkPage /> },
          { path: 'payment-link/:paymentLinkId', element: <BrightPayPaymentLinkViewEditPage /> },
          { path: 'settlements', element: <BrightPaySettlementsPage /> },
          { path: 'settlements/:settlementId', element: <BrightPaySettlementDetailsPage /> },
          { path: 'credits', element: <BrightPayOverviewPage /> },
          { path: 'qr', element: <BrightPayQrPanels /> },
          { path: 'payment-page/:paymentPageId', element: <BrightPayPaymentPage /> },
          { path: 'payment-page/:accountId/:paymentPageId', element: <BrightPayPaymentPage /> },
          { path: 'payment-pages', element: <BrightPayPaymentPages /> },
          { path: 'payment-pages/:accountId', element: <BrightPayPaymentPages /> },
          { path: 'payment-pages/:accountId/:type', element: <BrightPayPaymentPages /> },
        ],
      },
      {
        path: 'brightai',
        children: [
          { path: 'medisight', element: <BrightAiResearchAssistantPage /> },
        ],
      },
      {
        path: 'support',
        children: [
          { element: <HelpCenterPage />, index: true },
          { path: 'submit-ticket', element: <SubmitTicketPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
