import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '../store';
import axiosBrightHub from 'src/utils/axiosBrightHub';
import { ISearchResponse } from 'src/types/ai-search';
import * as researchAssistantSearchSchema from 'src/schemas/research-assistant';

import { endpoints } from 'src/utils/axios';

// Define the initial state using that type
interface AISearchState {
    isLoading: boolean;
    hasLoaded: boolean;
    error: string | null;
    userId: string | null;
    sessionId: string | null;
    query: string | null;
    queryType: string | null;
    querySubtype: string | null;
    queryCategory: string | null;
    originalQuery: string | null;
    querySchema: keyof typeof researchAssistantSearchSchema | null;
    searchResponse: ISearchResponse | null;
  }
  
  const initialState: AISearchState = {
    isLoading: false,
    error: null,
    hasLoaded: false,
    userId: null,
    sessionId: null,
    query: null,
    queryType: null,
    querySubtype: null,
    queryCategory: null,
    querySchema: null,
    originalQuery: null,
    searchResponse: null,
  };

// Asynchronous thunk action to update specific fields
export const updateSearchResponse = createAsyncThunk(
  'search/updateSearchResponse',
  async (
    {
      sessionId,
      key,
      data
    }: {
      sessionId: string;
      key: keyof ISearchResponse; // Define key to match the state fields
      data: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const url = endpoints.aisearch.updateResponse;
      const response = await axiosBrightHub.post(url, {
        sessionId,
        key,
        data
      });
      return { key, data: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

  // Asynchronous thunk action to update specific fields
export const storeSearchResponse = createAsyncThunk(
    'search/storeSearchResponse',
    async (
      {
        sessionId,
        key,
        data
      }: {
        sessionId: string;
        key: keyof ISearchResponse; // Define key to match the state fields
        data: any;
      },
      { rejectWithValue }
    ) => {
      try {
        const url = endpoints.aisearch.storeResponse;
        const response = await axiosBrightHub.post(url, {
          sessionId,
          key,
          data
        });
        return { key, data: response.data };
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const aiSearchSlice = createSlice({
    name: 'aiSearch',
    initialState,
    reducers: {
      setLoading(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload;
      },
      setQuery(state, action: PayloadAction<string>) {
        state.query = action.payload;
      },
      setOriginalQuery(state, action: PayloadAction<string>) {
        state.originalQuery = action.payload;
      },
      setQueryType(state, action: PayloadAction<string>) {
        state.queryType = action.payload;
      },
      setQuerySubtype(state, action: PayloadAction<string>) {
        state.querySubtype = action.payload;
      },
      setQueryCategory(state, action: PayloadAction<string>) {
        state.queryCategory = action.payload;
      },
      setQuerySchema(state, action: PayloadAction<keyof typeof researchAssistantSearchSchema>) {
        state.querySchema = action.payload;
      },
      setSessionId(state, action: PayloadAction<string>) {
        state.sessionId = action.payload;
      },
      resetContext(state) {
        state.queryType = null;
        state.querySubtype = null;
        state.queryCategory = null;
        state.searchResponse = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(storeSearchResponse.pending, (state) => {
          state.isLoading = false;
        })
        .addCase(storeSearchResponse.fulfilled, (state, action) => {
          state.isLoading = false;
          state.hasLoaded = false;
        })
        .addCase(storeSearchResponse.rejected, (state, action) => {
          state.isLoading = false;
        });
    },
  });
  
  // Export actions
  export const {
    setLoading,
    resetContext,
    setQuery,
    setOriginalQuery,
    setQueryType,
    setQuerySubtype,
    setQueryCategory,
    setQuerySchema,
    setSessionId,
  } = aiSearchSlice.actions;
  
  // Export reducer
  export default aiSearchSlice.reducer;  